import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import Metadata from "../Metadata/Metadata";
import {
  setErrorMessage,
  setIsConfirm,
  setShowErrors,
  setTypeMessage,
} from "../../libs/redux/slices/Errorsxx/errorsSlice";
import { useUpdateFileMutation } from "../../libs/redux/slices/Trkgfile/trkgfileApislice";

const Trkeditx = ({ dataEdit, modalEdit, setModalEdit, tiposPermission }) => {
  console.log(dataEdit);
  console.log(tiposPermission);

  const dispatch = useDispatch();
  const { METADATX, IDARCHXX, JSONMETX, REGFECXX, METAEXCE } = dataEdit[0];
  console.log(JSONMETX);
  console.log(METAEXCE);
  const metaDocument = Object.keys(JSONMETX);
  //Filtro los permisos del usuario de acuerdo a la informacion del documento
  const metadata = tiposPermission[0].GEST0004.filter((meta) => metaDocument.includes(meta.METIDXXX));
  const [updateFile, { isLoading }] = useUpdateFileMutation();
  const [extraFilter, setExtraFilter] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [isEmptyValues, setIsEmptyValues] = useState(false);

  //Seteo los metadatos que vienen
  const [jsonmetx, setJsonmetx] = useState(JSONMETX);
  const updateJsonmetx = (key, value) => {
    setIsChange(true);
    setJsonmetx((state) => ({ ...state, [key]: value }));
  };
  const onUpdateMetadata = () => {
    let isSave = true;
    metadata.forEach((meta) => {
      if (meta.BANOBLXX === "SI") {
        if (jsonmetx[meta.METIDXXX] === "") {
          isSave = false;
        }
      }
    });
    if (!isSave) {
      setIsEmptyValues(true);
    } else {
      setIsEmptyValues(false);
    }
    if (isChange) {
      if (isSave) {
        updateFile({ JSONMETX: { ...jsonmetx }, IDARCHXX, anio: REGFECXX.split('-')[0] })
          .unwrap()
          .then((response) => {
            setModalEdit(false);
            dispatch(setIsConfirm(false));
            dispatch(setShowErrors(true));
            dispatch(setErrorMessage({ message: "Datos actualizados con Exito!" }));
            dispatch(setTypeMessage("Confirmacion"));
          })
          .catch(() => {
            dispatch(setTypeMessage("Error"));
            dispatch(setErrorMessage({ message: "Se presentaron problemas al actualizar Metadatos." }));
          });
      }
    } else {
      setModalEdit(false);
    }
  };
  useEffect(() => {
    const metas = Object.keys(jsonmetx);
    if (metas.includes("DOCNROXX") && metas.includes("CLIIDXXX")) {
      setExtraFilter(jsonmetx["CLIIDXXX"]);
    }
  }, [jsonmetx]);
  let modalClose = {};
  if (isChange) {
    modalClose = { backdrop: "static" };
  }
  const allKeys = [...new Set([...Object.keys(METADATX), ...tiposPermission[0].GEST0004.map((meta) => meta.METIDXXX)])];
  return (
    <Modal show={modalEdit} onHide={() => setModalEdit(false)} size="lg" {...modalClose}>
      <Modal.Header closeButton>Edicion de Documento {IDARCHXX}</Modal.Header>
      <Modal.Body>
        <Container>
          {allKeys.map((key, index) => {
            const isKeyInDataEdit = Object.prototype.hasOwnProperty.call(METADATX, key);
            const isKeyInGest = tiposPermission[0].GEST0004.some((meta) => meta.METIDXXX === key);

            if (isKeyInDataEdit) {
              return (
                <Row key={index}>
                  <Col className="mb-2">
                    {key}:
                    {metadata.map((data, metaIndex) => {
                      return (
                        <div key={metaIndex}>
                          {key === data.METDESXX ? (
                            <Metadata
                              metadat={data}
                              setJsonmetx={updateJsonmetx}
                              defaultValue={key === "CONTROLADOR" ? METADATX[key]?.label : METADATX[key]}
                              extraFilter={extraFilter}
                              isDisabled={data.METIDXXX === "CLIIDXXX"}
                              metaExcel={METAEXCE}
                              jsonmetx={jsonmetx}
                              fechcrea={REGFECXX}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </Col>
                </Row>
              );
            } else if (isKeyInGest) {
              const gestMetadata = tiposPermission[0].GEST0004.find((meta) => meta.METIDXXX === key);
              const isKeyInMetadata = metadata.some((data) => data.METIDXXX === key || data.METDESXX === key);
              if (!isKeyInMetadata) {
                return (
                  <Row key={index}>
                    <Col className="mb-2">
                      {gestMetadata.METDESXX}
                      <div key={gestMetadata.METIDXXX}>
                        <Metadata
                          metadat={gestMetadata}
                          setJsonmetx={updateJsonmetx}
                          defaultValue=""
                          extraFilter={extraFilter}
                          isDisabled={gestMetadata.METIDXXX === "CLIIDXXX"}
                          metaExcel={METAEXCE}
                          jsonmetx={jsonmetx}
                          fechcrea={REGFECXX}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              }
            }
          })}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {isEmptyValues ? (
          <>
            <Alert key="danger" variant="danger" onClose={() => setIsEmptyValues(false)} dismissible>
              Campos vacios, por favor verifique.
            </Alert>
            <br />
          </>
        ) : (
          <>
            {isLoading ? (
              <Button className="btn-yellow m-2">
                <i className="spinner-border spinner-border-sm" role="status"></i> Guardando...
              </Button>
            ) : (
              <Button className="btn-yellow m-2" type="button" onClick={() => onUpdateMetadata()}>
                Guardar
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default Trkeditx;
