import React, { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  useLazyGetDoQuery,
  useLazyGetClientQuery,
  useLazyGetEmployeeQuery,
  useLazyGetProviderQuery,
  useLazyGetAgentQuery,
  useLazyGetLineaNavieraQuery,
  useLazyGetOpMapQuery,
  useLazyGetBkMapQuery,
  useLazyGetQtMapQuery,
  useLazyGetPoMapQuery,
  useLazyGetDoByClientQuery,
  useLazyGetReferenciaQuery,
  useLazyGetManifiestoQuery,
  useLazyGetComprobanteQuery,
  useLazyGetDataExcelQuery,
  useLazyGetOperationByAnioQuery,
} from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";
import { getAreaSelected, getEmpresaSelected } from "../../libs/redux/slices/autocomxSlice/autocomSlice";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useSelector } from "react-redux";
import { useLazyGetTransporterQuery, useLazyGetCimaClientQuery, useLazyGetUsrControllerQuery } from "../../libs/redux/slices/Cimaxxxx/cimaxxxxApiSlice";

const Autocomx = ({ objectMetadata, setJsonmetx, defaultValue, isMultiple = false, extraFilter = "", isDisabled, jsonmetx, fechcrea }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [findOperations] = useLazyGetDoQuery();
  const [findCimaClients] = useLazyGetCimaClientQuery();
  const [findClients] = useLazyGetClientQuery();
  const [findEmployees] = useLazyGetEmployeeQuery();
  const [findTransporters] = useLazyGetTransporterQuery();
  const [findProviders] = useLazyGetProviderQuery();
  const [findAgent] = useLazyGetAgentQuery();
  const [findUsrController] = useLazyGetUsrControllerQuery();
  const [findLineaNaviera] = useLazyGetLineaNavieraQuery();
  const [findOperationsMap] = useLazyGetOpMapQuery();
  const [findBookingsMap] = useLazyGetBkMapQuery();
  const [findQuotationsMap] = useLazyGetQtMapQuery();
  const [findPositionsMap] = useLazyGetPoMapQuery();
  const [findOperationsByClient] = useLazyGetDoByClientQuery();
  const areaSelec = useSelector(getAreaSelected);
  const findEmpresa = useSelector(getEmpresaSelected);
  const [findProducto] = useLazyGetReferenciaQuery();
  const [findManifiesto] = useLazyGetManifiestoQuery();
  const [findComprobante] = useLazyGetComprobanteQuery();
  const [finDataExcel] = useLazyGetDataExcelQuery();
  const [findDoByAnio] = useLazyGetOperationByAnioQuery();
  let findFunction = () => {};
  switch (objectMetadata.METOPXXX) {
    case "getDo":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          if (extraFilter === "" && !jsonmetx.CLIIDXXX ) {
            await findOperations(value)
              .then((response) => {
                let formatData = [];
                if (response?.data?.data) {
                  formatData = response.data.data.map((operation) => {
                    return { id: operation.DOCNROXX, label: operation.DOCNROXX };
                  });
                }
                setOptions(formatData);
                setIsLoading(false);
              })
              .catch(() => {
                setOptions([]);
                setIsLoading(false);
              });
          } else if (fechcrea !== "" && fechcrea !== undefined) {
            console.log("fechcrea", fechcrea);
            let cNiclient = extraFilter !== "" ? extraFilter : jsonmetx.CLIIDXXX;
            await findDoByAnio({ CLIIDXXX: cNiclient, DOCNROXX: value, REGFECXX: fechcrea })
              .then((response) => {
                let formatData = [];
                if (response?.data?.data) {
                  formatData = response.data.data.map((operation) => {
                    return { id: operation.id, label: operation.label };
                  });
                }
                setOptions(formatData);
                setIsLoading(false);
              })
              .catch(() => {
                setOptions([]);
                setIsLoading(false);
              });
          } else {
            let cNiclient = extraFilter !== "" ? extraFilter : jsonmetx.CLIIDXXX;
            await findOperationsByClient({ CLIIDXXX: cNiclient, DOCNROXX: value })
              .then((response) => {
                let formatData = [];
                if (response?.data?.data) {
                  formatData = response.data.data.map((operation) => {
                    return { id: operation.id, label: operation.label };
                  });
                }
                setOptions(formatData);
                setIsLoading(false);
              })
              .catch(() => {
                setOptions([]);
                setIsLoading(false);
              });
            }
        }
      };
      break;
    case "getClient":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          var filterEmpresa = `filters[CIPO0024.REGESTXX]=ACTIVO&filters[CLINOMXX][LIKE]=${value}%`;
          //findCimaClients
          let formatData = [];
          await findCimaClients(filterEmpresa)
            .then((response) => {
              console.log(response);
              if (response?.data?.data) {
                console.log(response.data.data);
                formatData = response.data.data.map((client) => {
                  return { id: client.CLIIDXXX, label: `${client.CLINOMXX} - ${client.CLIIDXXX}` };
                });
              }
            })
            .catch(() => {
              console.log("Error in findCimaClients call")
            })
            .finally(async ()=>{
              if(formatData.length>0){
                setOptions(formatData);
                setIsLoading(false);
              }else{
                switch (findEmpresa) {
                  case '830004237':
                    filterEmpresa = `&filters[MAPESTXX]=ACTIVO&filters[CLINOMXX][LIKE]=${value}%`;
                    break;
                  case '830104929':
                    filterEmpresa = `&filters[CLILOGRE]=SI&filters[REGESTXX][<>]=INACTIVO&filters[CLINOMXX][LIKE]=${value}%`;
                    break;
                  default:
                    filterEmpresa = `&filters[REGESTXX]=ACTIVO&filters[CLIESTCO]=ACTIVO&filters[CLINOMXX][LIKE]=${value}%`;
                    break;
                }
                await findClients(filterEmpresa)
                  .then((response) => {
                    let formatData = [];
                    if (response?.data?.data?.data) {
                      formatData = response.data.data.data.map((client) => {
                        return { id: client.CLIIDXXX, label: client.CLINOMXX };
                      });
                    }
                    setOptions(formatData);
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setOptions([]);
                    setIsLoading(false);
                  });
              }
            });
        }
      };
      break;
    case "getEmployee":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findEmployees(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((employee) => {
                  return { id: employee.USRIDXXX, label: employee.EMPNOCOX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getTransporter":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findTransporters(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((transporter) => {
                  return { id: transporter.id, label: `${transporter.id} - ${transporter.label}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getProvider":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          let filterEmpresa = "";
          switch (areaSelec) {
            case "115":
              filterEmpresa = `filters[REGESTXX]=ACTIVO&filters[PIENOMXX][LIKE]=${value}`;
              break
            default:
              filterEmpresa = `filters[REGESTXX]=ACTIVO&filters[PIEAABBY]=SI&filters[PIENOMXX][LIKE]=${value}`;
              break
          }
          await findProviders(filterEmpresa)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((provider) => {
                  return { id: provider.PIEIDXXX, label: provider.PIENOMXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getAgent":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findAgent(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((Agent) => {
                  return { id: Agent.AGEIDXXX, label: Agent.AGEDESXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getLineaNaviera":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findLineaNaviera(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((Navier) => {
                  return { id: Navier.LINIDXXX, label: Navier.LINDESXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getOpMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findOperationsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.OPOPEXXX, label: operation.OPOPEXXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getBkMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findBookingsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.OSNOMRES, label: operation.OSNOMRES };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getQtMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findQuotationsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.QUONUMXX, label: operation.QUONUMXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getPoMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findPositionsMap(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((operation) => {
                  return { id: operation.OPPOSXXX, label: operation.OPPOSXXX };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getClientMap":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findClients({value : value, campo: "CLINOMXX"})
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((client) => {
                  return { id: client.CLIIDXXX, label: `${client.CLINOMXX} - ${client.CLIIDXXX}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getReferencia":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          if ( jsonmetx.CLIIDXXX ) {
            await findProducto({value: value, CLIIDXXX: jsonmetx.CLIIDXXX })
            .then((response) => {
              let formatData = [];
              if (response?.data?.data?.data) {
                formatData = response.data.data.data.map((producto) => {
                  return { id: producto.prorefxx, label: `${producto.prorefxx} - ${producto.prodesxx}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
          }
        }
      };
      break;
    case "getManifiesto":
      isMultiple = true;
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findManifiesto(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((manifiesto) => {
                  return { id: manifiesto.maniidxx, label: `${manifiesto.maniidxx} - ${manifiesto.manititu}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getComprobante":
      isMultiple = true;
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await findComprobante(value)
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((comprobante) => {
                  return { id: comprobante.manicomp, label: `${comprobante.manicomp}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "getDataEXcel":
      findFunction = async (value) => {
        setIsLoading(true);
        if (value !== "") {
          await finDataExcel({DOCEVALX: value, METIDXXX:objectMetadata.METIDXXX })
            .then((response) => {
              let formatData = [];
              if (response?.data?.data) {
                formatData = response.data.data.map((dataExcel) => {
                  return { id: dataExcel.id, label: `${dataExcel.label}` };
                });
              }
              setOptions(formatData);
              setIsLoading(false);
            })
            .catch(() => {
              setOptions([]);
              setIsLoading(false);
            });
        }
      };
      break;
    case "f_getController":
      findFunction = async (value, FILTCAMP = "COTUSRXX") => {
        setIsLoading(true);
        console.log(jsonmetx)
        if (value !== "") {
          var filterController = `filters[CIMS0001.USRNOMXX][LIKE]=${value}%&filters[CIMS0072.MODIDXXX]=114&filters[CIMS0072.PROIDXXX]=100`;
          let formatData = [];
          await findUsrController({
            filter: filterController,
            CLIIDXXX: jsonmetx?.CLIIDXXX?.trim() ? jsonmetx.CLIIDXXX : null,
            APLALLXX: jsonmetx?.USERDIFE === "SI"
          }).then((response) => {
              if (response?.data?.data) {
                formatData = response.data.data.map((descController) => {
                  return {
                    id:       descController.value,
                    label:    descController.label,
                    COTIDXXX: descController.COTIDXXX,
                    COTDESXX: descController.COTDESXX,
                  };
                });
              }
            })
            .catch(() => {
              console.log("Error in findCimaClients call")
            })

            .finally(async ()=>{
              if (FILTCAMP === "USRCONTR") {
                setJsonmetx(objectMetadata.METIDXXX, formatData[0].id, formatData[0].label);
              } else {
                setOptions(formatData);
              }
              setIsLoading(false);
            });
        }
      };
      break;
    default:
      break;
  }
  return (
    <AsyncTypeahead
      id={objectMetadata.METIDXXX}
      multiple={isMultiple}
      defaultSelected={defaultValue}
      filterBy={["id", "label"]}
      placeholder="Buscar..."
      searchText="Buscando..."
      emptyLabel="Sin registros"
      promptText="Escriba para buscar"
      minLength={3}
      isLoading={isLoading}
      options={options}
      onChange={(optionsSelected) => {
        const key = objectMetadata.METIDXXX;
        if (optionsSelected.length > 0 && !isMultiple) {
          setJsonmetx(key, optionsSelected[0].id);
          if(key === "USRCONTR"){
            setJsonmetx(key, optionsSelected[0], optionsSelected[0].label);
          }
        } else if (optionsSelected.length > 0 && isMultiple) {
          let optionsValues = [];
          optionsSelected.forEach((option) => {
            optionsValues.push(option.id);
          });
          setJsonmetx(key, optionsValues);
        } else {
          setJsonmetx(key, "");
        }
      }}
      onSearch={findFunction}
      inputProps={{
        readOnly: isDisabled,
      }}
    />
  );
};

export default Autocomx;
